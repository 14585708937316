<template>
    <div class="icon-unit">
        <!-- <div class="icon-unit-subtitle">
            <p class="icon-unit-subtitle-title">全局病案号是否必填：</p>
            <p class="icon-unit-subtitle-value">
                <el-switch class="icon-unit-subtitle-value-switch" v-model="isPatientIdRequired"
                    active-color="#6DA4D8" inactive-color="#DCDFE6" disabled>
                </el-switch>
            </p>

        </div> -->
        <div class="icon-unit-subtitle">
            <p class="icon-unit-subtitle-title">问卷是否显示病案号：</p>
            <p class="icon-unit-subtitle-value">
                <el-switch class="icon-unit-subtitle-value-switch" v-model="isPatientIdDisplay"
                    active-color="#6DA4D8" inactive-color="#DCDFE6" disabled>
                </el-switch>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isPatientIdRequired: Boolean,
        isPatientIdDisplay: Boolean
    },
};
</script>


<style lang="scss" scoped>
.icon-unit {
    color: black;
    font-size: rem(14);

    &-title {
        height: rem(20);
    }

    &-subtitle {
        display: flex;
        align-items: center;
        margin-top: rem(18);
        height: rem(20);

        &-value {
            margin-left: rem(14);

            &-switch {
                
            }
        }
    }

    &-bottom {
        margin-top: rem(18);
        display: flex;

        &-key {
            height: rem(20);
        }

        &-icon {
            width: rem(70);
            height: rem(70);
        }
    }
}
</style>